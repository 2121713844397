import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/auth';
import { captureException } from '@sentry/browser';
import { getAuthToken, isClient } from '../reactjs/utils';
import isIndexedDBOpenable from './idb-check';

const firebaseConfig = {
  apiKey: 'AIzaSyAm1wxM07b2jcLJA3N2m9SCM4gHCIXSJ48',
  authDomain: 'polydice-icook.firebaseapp.com',
  databaseURL: 'https://polydice-icook.firebaseio.com',
  projectId: 'polydice-icook',
  storageBucket: 'polydice-icook.appspot.com',
  messagingSenderId: '380107654687',
  appId: '1:380107654687:web:ce31f268f4e9aba02db72a',
  measurementId: 'G-2PGLTQKZCL'
};

export function triggerMessagingAuth() {
  if (!firebase.messaging.isSupported()) return;

  const PUBLIC_VAPID_KEY =
    'BOFAzSi6um347aiWWnY8FwDw8mkfaT2ign6B3xwfxcZFYCXI06FXRKFaE2eArWKH79yJx_Ba4Fbmm4SizbZzPrY';

  firebase
    .messaging()
    .getToken({ vapidKey: PUBLIC_VAPID_KEY })
    .then((token) => {
      const payload = {
        token,
        language: window.navigator.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      fetch(`/devices/${token}`, {
        method: 'PUT',
        headers: {
          'X-XSRF-Token': getAuthToken(),
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
        body: JSON.stringify(payload)
      });
    })
    .catch((error) => {
      if (Notification.permission === 'granted') {
        captureException(error);
      }
    });
}

const shouldInit = isClient() && process.env.NODE_ENV !== 'test';

const firebaseApp = shouldInit && firebase.initializeApp(firebaseConfig);

let analytics: firebase.analytics.Analytics | undefined;
if (shouldInit) {
  isIndexedDBOpenable().then((isOpenable) => {
    if (!isOpenable) return;

    firebase.analytics.isSupported().then((isSupported) => {
      if (isSupported) {
        analytics = firebase.analytics();
        analytics.setUserId(window.ICOOK.user_id);
        analytics.setUserProperties({
          user_type: window.ICOOK.user_id
            ? window.ICOOK.vip
              ? 'vip'
              : 'member'
            : 'guest'
        });
        // firebase tracking
        window.firebaseEvents = window.firebaseEvents || [];
        window.firebaseEvents.forEach(({ name, payload }) => {
          analytics!.logEvent(name, payload);
        });
      }
    });
  });
}

export { firebaseApp, analytics };
