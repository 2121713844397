import perfMark from 'components/perfMark';
const perfMarker = perfMark('SearchBox component');
perfMarker.markStart();

import { Component } from 'react';
import PropTypes from 'prop-types';

import DeskSearch from './deskSearch';
import MobileSearch from './mobileSearch';

class SearchBox extends Component {
  static async ajaxOpts(url, params, cbs = {}, type = 'recipe', isMobile) {
    try {
      const res = await fetch(
        `${url}?${Object.entries({ ...params, type })
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&')}`
      );
      const data = await res.json();
      const options = data.results.map((i) => ({
        completed: !isMobile,
        value: i
      }));

      if (cbs.done) {
        cbs.done(options);
      }
    } catch (e) {
      if (cbs.fail) {
        cbs.fail();
      }
    }
  }

  constructor(props, context) {
    super(props, context);

    this.setActionPath = this.setActionPath.bind(this);
    this.setSubmitState = this.setSubmitState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      canSubmit: false,
      formAction: props.searchUrl
    };
  }

  handleSubmit(event) {
    if (this.state.formAction === this.props.searchUserUrl) {
      return;
    }
    event.preventDefault();

    const form = event.target;
    const questionInput = form.querySelector('input[name="q"]');
    const question = questionInput
      ? encodeURIComponent(form.querySelector('input[name="q"]').value)
      : '';
    const ingredientsInput = form.querySelector('input[name="ingredients"]');
    const ingredients = ingredientsInput
      ? encodeURIComponent(
          form
            .querySelector('input[name="ingredients"]')
            .value.trim()
            .replace(/[\s,，。、]+/g, ',')
            .split(',')
            .filter((item) => item.length > 0)
            .filter((item, index, array) => array.indexOf(item) === index)
            .sort()
            .join(',')
        ).replace(/%2C/g, ',')
      : '';
    if (question && ingredients) {
      window.location.href = `/search/${question}/${ingredients}/`;
    } else if (question) {
      window.location.href = `/search/${question}/`;
    } else if (ingredients) {
      window.location.href = `/search/食材：${ingredients}/`;
    }
  }

  setActionPath(type) {
    const url =
      type === 'user' ? this.props.searchUserUrl : this.props.searchUrl;
    this.setState({ formAction: url });
  }

  setSubmitState(isValidate) {
    this.setState({ canSubmit: isValidate });
  }

  render() {
    const { isMobile, isVip, className, tracker } = this.props;
    const { formAction, canSubmit } = this.state;
    const { setActionPath, setSubmitState } = this;
    const props = Object.assign({}, this.props, {
      ajaxOpts: SearchBox.ajaxOpts,
      setActionPath,
      setSubmitState
    });
    return (
      <form
        acceptCharset="UTF-8"
        id="global-search"
        className={className}
        action={formAction}
        onSubmit={this.handleSubmit}
      >
        <div className="to-search">
          {isMobile ? <MobileSearch {...props} /> : <DeskSearch {...props} />}
          {isVip && formAction === this.props.searchUrl && (
            <input type="hidden" name="order" value="popular" />
          )}
          <button
            className="btn-search"
            type="submit"
            disabled={!canSubmit}
            onClick={tracker}
            aria-label="搜尋"
            data-track-id="track-search-submit"
          >
            <i className="icon-search-regular" />
          </button>
        </div>
      </form>
    );
  }
}

SearchBox.propTypes = {
  autocompleteUrl: PropTypes.string,
  ingredients: PropTypes.string,
  isMobile: PropTypes.bool,
  isVip: PropTypes.bool,
  q: PropTypes.string,
  className: PropTypes.string,
  searchUrl: PropTypes.string,
  searchUserUrl: PropTypes.string,
  url: PropTypes.string,
  tracker: PropTypes.func
};

SearchBox.defaultProps = {
  autocompleteUrl: '',
  ingredients: '',
  isMobile: false,
  isVip: false,
  q: '',
  className: '',
  searchUrl: '',
  searchUserUrl: '',
  url: '',
  tracker: () => {}
};

export default SearchBox;
perfMarker.markEnd();
perfMarker.measure();
